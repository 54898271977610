import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import React, { useState } from "react"
import { css } from "@emotion/react"

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET
}

const Image = ({ id, className, width, alt, src }) => {

  const [loaded, setLoaded] = useState(false)
  let fluidProps

  if (id && !/gif/.test(id)) {
    fluidProps = getFluidGatsbyImage(id, { maxWidth: width || 2400 }, sanityConfig)
  }

  return (
    <div className={className ?? ''} css={css`position: relative;`}>
      {fluidProps ? (
        <Img fluid={fluidProps} alt={alt} onLoad={() => setLoaded(true)} />
      ) : (
          <img
            alt={alt}
            src={src ? src : undefined}
            className={loaded ? 'loaded' : 'loading'}
            onLoad={() => {
              setLoaded(true)
            }} />
        )}
      <div css={css`
        background-image: url('/static.png');
        image-rendering: pixelated;
        /* padding-bottom: calc(1 / ${fluidProps.aspectRatio} * 100%);  */
        background-repeat: repeat;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: opacity 0.3s 0.5s;
        opacity: ${loaded ? '0' : '1'};
        background-size: 350px;`
      }></div>
    </div>
  )
}

export default Image
